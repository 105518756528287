<template>
  <div style="height: 100vh; overflow: hidden;">
    <div>
      <div v-if="addressList.length === 0">
        <!-- 无收获地址时 -->
        <div class="height-100">
          <!-- <van-empty description="未添加收货地址，去添加" class="empt font-s-16">
          </van-empty> -->
        </div>
      </div>

      <!--有收货地址 -->
      <div v-else style="padding-top: 1rem;height: 100vh; overflow-y: scroll;">
        <div v-for="(item, index) in addressList" :key="index" class="addressItem"
          :style="{ marginBottom: index + 1 == addressList.length ? '6rem' : '0rem' }">
          <div>
            <div style="display: flex; justify-content:space-between;">
              <div style="display: flex; flex-direction: row; align-items: center;">
                <span style="font-size: 1rem;font-weight: 600;"> {{ item.recipient }}</span>
                <span style="font-size: 0.85rem; margin-left: 1rem;margin-right: 1rem;font-weight: 600;">
                  {{ getPhone(item.recipientMobile) }} </span>
                <span class="default" v-if="item.defaultFlag == '1'"> 默认</span>
              </div>

            </div>
            <div style="margin-top: 0.5rem;margin-bottom: 0.7rem;margin-right: 2rem;">
              <span>{{ item.addressCname }}{{ item.addressQname }}{{ item.addressPname }}</span>
              {{ item.address }}{{ item.houseNum }}
            </div>
            <div class="buttomitem">

              <div icon-size="1rem" @click="checkDeault(item)">
                <van-icon name="circle" v-if="item.defaultFlag == '0'" />
                <van-icon v-else name="checked" color="#D33B2E" />
                默认地址
              </div>

              <div 
                style="color: #D33B2E;font-size: 1rem; display: flex;flex-direction: row;">
                <div @click="onEdit(item, index)" style="font-size: 1rem;"> <van-icon size="1rem" name="edit" /> 编辑</div>
                <van-icon style="margin-top: 0.1rem;margin-left: 0.5rem;" size="1.1rem" name="delete-o" @click="delAddress(item)"/> 删除
              </div>
            </div>
          </div>

        </div>

      </div>
      <div
        style="display: flex; position: absolute;width: 100%;bottom: 1rem; align-items: center;justify-content: center;">
        <div class="addAddress" @click="onAdd">
          新建收货地址
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { del } from 'vue';

export default {
  name: "",
  props: {},
  data() {
    return {
      // 默认选中地址
      addressList: [
      ],
    };
  },
  components: {},
  methods: {
    checkDeault(val) {
      let args = {
        id: val.id,
        recipient: val.recipient,
        recipientMobile: val.recipientMobile,
        defaultFlag: val.defaultFlag == '1' ? '0' : '1',
        address: val.address,
        lat: val.lat,
        lng: val.lng,
        addressPname: val.addressPname,
        addressCname: val.addressCname,
        houseNum: val.houseNum,
        addressQname: val.addressQname,
        userId: val.userId,
        status: '1'
      };

      this.$api
        .updateAddress({ ...args })
        .then((res) => {
          this.$Toast(res.msg);
          if (res.code == 1) {
            return
          }
          this.getAddress()
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 返回
    goback() {
      // 地址列表只能返回两个地方
      // 判断是从地址管理过来还是支付页面过来
      if (localStorage.getItem("toOder")) {
        this.$router.push("/ToOrder");
        localStorage.removeItem("toOder");
      } else {
        this.$router.push("/My");
      }
    },
    // 添加地址
    onAdd() {
      this.$router.push("/EditAddress");
    },
    // 编辑地址
    onEdit(item, index) {
      this.$publicJs.go("/EditAddress");
      //
      localStorage.setItem("addressId", JSON.stringify(item));
    },
    // 获取地址列表数据
    getAddress() {
      let userId = localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo")).id
        : "";

      this.$api
        .getAddress(userId)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
          this.addressList = res.data;
        })
        .catch();
    },

    delAddress(item) {
      let args = {
        id: item.id,
        status: '0'
      };
      this.$api
        .updateAddress(args)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
          this.getAddress()
        })
        .catch();
    },
    // 点击选中事件
    select(item) {
      // 判断是从地址管理过来还是支付页面过来
      if (localStorage.getItem("toOder")) {
        this.$router.push("/ToOrder");
        localStorage.removeItem("toOder");
        // 本地
        localStorage.setItem("oderAdderss", JSON.stringify(item));
      } else {
        return;
      }
    },
    getPhone(phone) {
      return this.$utils.formPhone(phone);
    }
  },
  mounted() {
    this.getAddress();
  },
  computed: {},
  watch: {

  },
};
</script>

<style lang='scss' scoped>
.addressItem {
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  background-color: white;
  padding: 1rem 1rem 0 1rem;
}

.buttomitem {
  display: flex;
  justify-content: space-between;
  border-top: #AAA 1px dashed;
  margin: 0.2rem;
  height: 3rem;
  align-items: center;
}

.default {
  border-radius: 0.2rem;
  font-size: 0.8rem;
  background-color: red;
  color: #E8E8E8;
  padding: 0 0.3rem 0.1rem 0.5rem;

}

.addAddress {
  height: 3rem;
  border-radius: 2rem;
  line-height: 3rem;
  font-size: 1.1rem;
  margin: 0;
  background-image: linear-gradient(left, #FFC585 0%, #D59A54 100%);
  color: #f5f5f5;
  text-align: center;
  width: 90%;
}
</style>